<template>
  <div class="feedback-wrap">
    <Header :title="'个人详情'" :subTitleColor="'#fff'"  :bgColor="'#14151e'" />
    <div class="dot-line"></div>
    <div class="feedback-box">
      <div class="title">{{ $t('feedback.report') }}</div>
      <div class="reason">
        <p>{{ $t('feedback.reportType') }}</p>
        <van-radio-group v-model="selectedType">
          <van-radio v-for="(item, index) in feedbackType" :key="index" :name="item.value" checked-color="#FD2084">{{item.label}}</van-radio>
        </van-radio-group>
      </div>
      <div class="desc">
        <p>{{ $t('feedback.desc') }}</p>
        <div class="content">
          <van-field
              v-model="description"
              rows="4"
              type="textarea"
              :placeholder="$t('feedback.descPlaceHolder')"
          />
        </div>
      </div>
      <div class="upload-box">
        <VanUploader v-model="fileList" :after-read="afterRead" :before-delete="deletePhoto" :deletable="true" :max-count="9"
                     multiple accept="image/*">
          <div class="upload">
            <img src="@/assets/img/user/upload-center.png"  alt=""/>
          </div>
        </VanUploader>
      </div>
    </div>
    <vanButton :loading="loading" id="saveBtn" class="save-btn" @click="handleFeedbackUser">{{$t('common.confirm')}}</vanButton>
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import {uploadFile} from "@/service/upload";
import {getFeedbackType, submitFeedback} from "@/service/feedback";

export default {
  name: "feedback",
  components: {Header},
  data() {
    return {
      userId: null,
      feedbackType: [],
      selectedType: null,
      description: '',
      fileList:[],
      uploadPhotos: [],
      loading: false
    }
  },
  methods: {
    async init() {
      const resp = await getFeedbackType()
      if (resp.success) {
        this.feedbackType = resp.data
      }
    },
    async uploadImage(file) {
      let url = ''
      const resp = await uploadFile(file, 'image')
      if (resp.success) {
        url = resp.s3Key
      }
      return url
    },
    async afterRead(files) {
      if (files.length !== undefined) { //多张图片
        for (let i = 0; i < files.length; i++) {
          files[i].status = 'uploading'
          files[i].message = '上传中...'
          let url = await this.uploadImage(files[i].file)
          files[i].status = 'done'
          if (url) {
            this.uploadPhotos.push(url)
          } else {
            this.fileList.splice(this.fileList.length - 1, 1)
          }
        }
      } else {
        // 单张图片
        files.status = 'uploading'
        files.message = '上传中...'
        let url = await this.uploadImage(files.file);
        files.status = 'done'
        if (url) {
          this.uploadPhotos.push(url)
        }
      }
    },
    async deletePhoto(file, detail) {
      this.uploadPhotos = this.uploadPhotos.splice(detail.index)
    },
    async handleFeedbackUser() {
      if (!this.selectedType) return this.$toast('请选择举报类型')
      const params = {
        userId: this.userId,
        type: this.selectedType
      }
      if (this.description) {
        params.description = this.description
      }
      if (this.uploadPhotos.length > 0) {
        params.imgs = this.uploadPhotos.join(",")
      }
      this.loading = true
      document.getElementById("saveBtn").disabled = true
      const resp = await submitFeedback(params)
      this.loading = false
      if (resp.code === 200) {
        this.$toast('举报成功')
        setTimeout(()=>{
          this.$gbUtils.handleBack()
        },1000)
      }
    }
  },
  mounted() {
    this.userId = this.$route.query.userId
    document.getElementById("saveBtn").disabled = false
    this.init()
  }
}

</script>

<style scoped lang="scss">

  .feedback-wrap {
    position: relative;

    .feedback-box {
      padding: 72px 20px 20px 20px;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .reason {
        margin-top: 20px;
        p {
          color: #fff;
          opacity: .6;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 20px;
        }
      }
      .desc {
        color: #fff;
        font-size: 14px;
        margin-bottom: 15px;
        p {
          opacity: .6;
          margin-bottom: 20px;
          font-weight: bold;
        }
        .content {
          width: 100%;
          color: #fff;
          background: #14151e;
          caret-color: #FD2084;
          font-size: 14px;
          opacity: 0.5;
        }
      }
      .upload-box {
        .upload {
          width: 60px;
          height: 60px;
          border-radius: 4px;
          background-image: url('~@/assets/img/user/upload-border.png');
          background-size: 60px 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;

          img {
            width: 25.846px;
            height: 25.846px;
          }
        }
      }
    }

    .save-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 38px;
      font-size: 13px;
      font-weight: 600;
      border-radius: 4px;
      background: #8A73D0;
      margin: 20px auto;
    }


    .dot-line {
      width: calc(100% - 40px);
      border: 1px solid #3D3F51;
      position: absolute;
      top: 52px;
      left: 0;
      margin-left: 20px;
    }
  }

  /deep/.van-radio-group {
    margin-left: 10px !important;
  }
  /deep/.van-radio {
    margin-bottom: 15px !important;
  }
  /deep/.van-radio__label {
    color: #fff;
    font-size: 14px;
  }
  /deep/.van-field__control {
    background-color: #14151e;
    color: #fff !important;
    margin-left: 5px;
  }
  .van-cell {
    background-color: #14151e;
  }
  /deep/.van-radio__icon .van-icon {
    border: 1px solid #fff;
    border-color: rgba(255,255,255,0.6);
  }
  /deep/.van-uploader__preview-image {
    width: 60px !important;
    height: 60px !important;
  }
  /deep/.van-button__text {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }


</style>