import request from '../utils/request'

/**
 * 获取举报类型
 * @returns
 */
export const getFeedbackType = () => {
    return request('POST', '/playerBase/feedbackType', null, true, false)
}

/**
 * 举报用户
 * @param params
 */
export const submitFeedback = (params) => {
    return request('POST', '/playerFeedback/add', params, true, false)
}

/**
 * 喜欢用户
 * @param params 
 * @returns 
 */
export const userLike = (params) => {
    return request('POST', '/playerFeedback/like', params, true, false)
}

/**
 * 不喜欢用户
 * @param params 
 * @returns 
 */
export const userDislike = (params) => {
    return request('POST', '/playerFeedback/disLike', params, true, false)
}

/**
 * 获取喜欢和不喜欢的资源
 * @param params 
 * @returns 
 */
export const getLikeAndDislike = (params) => {
    return request('POST', '/commonBase/queryLikeAndDisLikes', params, true, false)
}

