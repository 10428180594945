import request from '../utils/request'
import axios from 'axios'

/**
 * 上传文件
 * @param file
 * @param type 图片类型: image 视频类型: video
 */
export const uploadFile = async (file, type) => {
  console.log('uploadFile file=', file)
  const signParam = {
    ext: file.name.substring(file.name.lastIndexOf('.') +1 ),
    ContentType: file.type,
    type: type
  }
  
  let s3Key = ''
  let result = {}
  // 1. 获取预签名URL
  const signUrlResp = await getS3PreSignUrl(signParam)
  // console.log('signUrlResp==>', signUrlResp)
  if (signUrlResp.code === 200) {
    s3Key = signUrlResp.data.url
    try {
      // 2. 根据预签名URL上传文件
      await uploadFileWithPreSign(signUrlResp.data.signedUrl, file)
      result = {
        success: true,
        s3Key: s3Key
      }
    } catch (e) {
      console.log(e)
      result = {
        success: false,
        message: e
      }
    }
  } else {
    result = {
      success: false,
      message: "Get S3PreSignUrl Error"
    }
    console.log('signUrlResp',signUrlResp)
  }
  return result
}

/**
 * 获取S3预签名RUL
 * @param params
 */
const getS3PreSignUrl = async (params) => {
  return request('POST', '/playerBase/preSignedUploadUrl', params, true, false)
}

/**
 * 使用预签名URL上传文件
 * @param preSignUrl
 * @param file
 */
const uploadFileWithPreSign = async (preSignUrl, file) => {
  try {
    const response = await axios.put(preSignUrl, file, { headers: {"Content-Type": file.type}});
    if (response.status === 200) {
      console.log('文件上传成功');
    } else {
      console.log('文件上传失败');
      throw Error('文件上传失败');
    }
  } catch (e) {
    console.log('文件上传发生错误', e);
    throw e;
  }
}

